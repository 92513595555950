
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
  {
    office1 : file(relativePath: { eq: "images/about-us/office/office1.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office2 : file(relativePath: { eq: "images/about-us/office/office2.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office3 : file(relativePath: { eq: "images/about-us/office/office3.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office4 : file(relativePath: { eq: "images/about-us/office/office4.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office5 : file(relativePath: { eq: "images/about-us/office/office5.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office6 : file(relativePath: { eq: "images/about-us/office/office6.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office7 : file(relativePath: { eq: "images/about-us/office/office7.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office8 : file(relativePath: { eq: "images/about-us/office/office8.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office9 : file(relativePath: { eq: "images/about-us/office/office9.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office10 : file(relativePath: { eq: "images/about-us/office/office10.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office11 : file(relativePath: { eq: "images/about-us/office/office11.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office12 : file(relativePath: { eq: "images/about-us/office/office12.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office13 : file(relativePath: { eq: "images/about-us/office/office13.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office14 : file(relativePath: { eq: "images/about-us/office/office14.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office15 : file(relativePath: { eq: "images/about-us/office/office15.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office16 : file(relativePath: { eq: "images/about-us/office/office16.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office17 : file(relativePath: { eq: "images/about-us/office/office17.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office18 : file(relativePath: { eq: "images/about-us/office/office18.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office19 : file(relativePath: { eq: "images/about-us/office/office19.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office20 : file(relativePath: { eq: "images/about-us/office/office20.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office21 : file(relativePath: { eq: "images/about-us/office/office21.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office22 : file(relativePath: { eq: "images/about-us/office/office22.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office23 : file(relativePath: { eq: "images/about-us/office/office23.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office24 : file(relativePath: { eq: "images/about-us/office/office24.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office25 : file(relativePath: { eq: "images/about-us/office/office25.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office26 : file(relativePath: { eq: "images/about-us/office/office26.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office27 : file(relativePath: { eq: "images/about-us/office/office27.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office28 : file(relativePath: { eq: "images/about-us/office/office28.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office29 : file(relativePath: { eq: "images/about-us/office/office29.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    office30 : file(relativePath: { eq: "images/about-us/office/office30.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const WeAre = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query);
  const office1 = data.office1.childImageSharp.fluid;
  const office2 = data.office2.childImageSharp.fluid;
  const office3 = data.office3.childImageSharp.fluid;
  const office4 = data.office4.childImageSharp.fluid;
  const office5 = data.office5.childImageSharp.fluid;
  const office6 = data.office6.childImageSharp.fluid;
  const office7 = data.office7.childImageSharp.fluid;
  const office8 = data.office8.childImageSharp.fluid;
  const office9 = data.office9.childImageSharp.fluid;
  const office10 = data.office10.childImageSharp.fluid;
  const office11 = data.office11.childImageSharp.fluid;
  const office12 = data.office12.childImageSharp.fluid;
  const office13 = data.office13.childImageSharp.fluid;
  const office14 = data.office14.childImageSharp.fluid;
  const office15 = data.office15.childImageSharp.fluid;
  const office16 = data.office16.childImageSharp.fluid;
  const office17 = data.office17.childImageSharp.fluid;
  const office18 = data.office18.childImageSharp.fluid;
  const office19 = data.office19.childImageSharp.fluid;
  const office20 = data.office20.childImageSharp.fluid;
  const office21 = data.office21.childImageSharp.fluid;
  const office22 = data.office22.childImageSharp.fluid;
  const office23 = data.office23.childImageSharp.fluid;
  const office24 = data.office24.childImageSharp.fluid;
  const office25 = data.office25.childImageSharp.fluid;
  const office26 = data.office26.childImageSharp.fluid;
  const office27 = data.office27.childImageSharp.fluid;
  const office28 = data.office28.childImageSharp.fluid;
  const office29 = data.office29.childImageSharp.fluid;
  const office30 = data.office30.childImageSharp.fluid;

  return (
    <section className="m-office m-tabPanel">
      <div className="_wr">
        <div className="m-office__top">
          <h2 className="-thin">{t('aboutUs.ourOffices')}</h2>
          <span className="a-line -red"></span>
        </div>
        <div className="_w">
          <div className="_m4 m-office__double">
            <Image fluid={office1} />
            <Image fluid={office2} />
          </div>
          <div className="_m8">
            <Image fluid={office3} />
          </div>
          <div className="_m6">
            <Image fluid={office4} />
          </div>
          <div className="_m6">
            <Image fluid={office5} />
          </div>
          <div className="_m8">
            <Image fluid={office6} />
          </div>
          <div className="_m4 m-office__double">
            <Image fluid={office7} />
            <Image fluid={office8} />
          </div>
          <div className="_m6">
            <Image fluid={office9} />
          </div>
          <div className="_m6">
            <Image fluid={office10} />
          </div>
          <div className="_m4 m-office__double">
            <Image fluid={office11} />
            <Image fluid={office12} />
          </div>
          <div className="_m8">
            <Image fluid={office13} />
          </div>
          <div className="_m6">
            <Image fluid={office14} />
          </div>
          <div className="_m6">
            <Image fluid={office15} />
          </div>
          <div className="_m6">
            <Image fluid={office16} />
          </div>
          <div className="_m6">
            <Image fluid={office17} />
          </div>
          <div className="_m8">
            <Image fluid={office18} />
          </div>
          <div className="_m4 m-office__double">
            <Image fluid={office19} />
            <Image fluid={office20} />
          </div>
          <div className="_m6">
            <Image fluid={office21} />
          </div>
          <div className="_m6">
            <Image fluid={office22} />
          </div>
          <div className="_m6">
            <Image fluid={office23} />
          </div>
          <div className="_m6">
            <Image fluid={office24} />
          </div>
          <div className="_m4 m-office__double">
            <Image fluid={office25} />
            <Image fluid={office26} />
          </div>
          <div className="_m8">
            <Image fluid={office27} />
          </div>
          <div className="_m8">
            <Image fluid={office28} />
          </div>
          <div className="_m4 m-office__double">
            <Image fluid={office29} />
            <Image fluid={office30} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeAre;
