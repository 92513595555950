import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import IconZurich from "../../assets/images/city/zurich.svg"
import IconBaar from "../../assets/images/city/baar.svg"
import IconBerlin from "../../assets/images/city/berlin.svg"
import IconDubai from "../../assets/images/city/dubai.svg"
import IconToronto from "../../assets/images/city/toronto.svg"
import IconNovisad from "../../assets/images/city/novisad.svg"
import IconBelgrade from "../../assets/images/city/belgrade.svg"
import IconNis from "../../assets/images/city/nis.svg"
import IconEducation from "../../assets/images/about-us/education.svg"
import IconCulturalAfinity from "../../assets/images/about-us/cultural-afinity.svg"
import IconCommunication from "../../assets/images/about-us/communication.svg"
import IconTransport from "../../assets/images/about-us/transport.svg"
import { useTranslation } from "gatsby-plugin-react-i18next";
import { TABS } from "../headers/header-about";

const query = graphql`
query {
  map: file(relativePath: { eq: "images/about-us/map.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`;



const Location = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query);
  const map = data.map.childImageSharp.fluid;


  const officesContent = [
    {
      image: <IconZurich />,
      city: "Zurich",
      street: "Heinrichstrasse 200",
      zip: "8005 Zurich",
    },
    {
      image: <IconBaar />,
      city: "Baar",
      street: "Rathausstrasse 14",
      zip: "6340 Baar",
    },
    {
      image: <IconBerlin />,
      city: "Berlin",
      street: "Technopark 1 strasse",
      zip: "Busiiness bay",
    },
    {
      image: <IconDubai />,
      city: "Dubai",
      street: "3506 Burlington tower",
      zip: "Busiiness bay",
    },
    {
      image: <IconToronto />,
      city: "Toronto",
      street: "90 Queens Wharf Road Suite 911",
      zip: "Ontario",
    },
  ];

  const hubsContent = [
    {
      image: <IconBelgrade />,
      city: "Belgrade",
      street: "Novosadskog Sajma 2",
      zip: "11000 Belgrade",
    },
    {
      image: <IconNovisad />,
      city: "Novi Sad",
      street: "Novosadskog Sajma 2",
      zip: "21000 Novi Sad",
    },
    {
      image: <IconNis />,
      city: "Niš",
      street: "Novosadskog Sajma 2",
      zip: "18106 Niš",
    },

  ];

  const advantagesContent = [
    {
      image: <IconEducation />,
      title: `${t('aboutUs.location.keyAdvantages.cards.first.headline')}`,
      text: `${t('aboutUs.location.keyAdvantages.cards.first.text')}`,
    },
    {
      image: <IconCulturalAfinity />,
      title: `${t('aboutUs.location.keyAdvantages.cards.second.headline')}`,
      text: `${t('aboutUs.location.keyAdvantages.cards.second.text')}`,
    },
    {
      image: <IconCommunication />,
      title: `${t('aboutUs.location.keyAdvantages.cards.third.headline')}`,
      text: `${t('aboutUs.location.keyAdvantages.cards.third.text')}`,
    },
    {
      image: <IconTransport />,
      title: `${t('aboutUs.location.keyAdvantages.cards.fourth.headline')}`,
      text: `${t('aboutUs.location.keyAdvantages.cards.fourth.text')}`,
    },

  ];

  return (
    <section id={TABS.LOCATION} className="m-location m-tabPanel">
      <div className="_wr">
        <h2 className="-thin">{t('aboutUs.location.headline')}</h2>
        <span className="a-line -red"></span>
        <p className="m-location__topText">{t('aboutUs.location.text')}</p>
        <h3 className="-section">{t('aboutUs.location.offices')}</h3>
        <div className="_w m-location__cities">
          {officesContent.map(({ city, street, zip, image }, i) => (
            <div className="_8 _s6 _m4 _l2 m-location__city" key={i} >
              <div className="m-location__city--image">
                {image}
              </div>
              <span className="m-location__city--town">{city}</span>
              <span className="m-location__city--text -first">{street}</span>
              <span className="m-location__city--text -second">{zip}</span>
            </div>
          ))}
        </div>
        <h3 className="-section">{t('aboutUs.location.hubs')}</h3>
        <div className="_w m-location__cities -hubs">
          {hubsContent.map(({ city, street, zip, image }, i) => (
            <div className="_8 _s6 _m4 _l2 m-location__city" key={i} >
              <div className="m-location__city--image">
                {image}
              </div>
              <span className="m-location__city--town">{city}</span>
              <span className="m-location__city--text -first">{street}</span>
              <span className="m-location__city--text -second">{zip}</span>
            </div>
          ))}
        </div>
        <div className="m-location__map">
          <div className="m-location__map--legend">
            <span className="-red">{t('aboutUs.location.offices')}</span>
            <span className="-gray">{t('aboutUs.location.hubs')}</span>
          </div>
          <Image fluid={map} />
        </div>
        <h2 className="-thin -advantages">{t('aboutUs.location.keyAdvantages.headline')}</h2>
        <span className="a-line -red"></span>

        <div className="_w m-location__advantagesBlocks">
          {advantagesContent.map(({ title, text, image }, i) => (
            <div className="_l6">
              <div className="m-location__advantagesBlocks--block">
                <div className="m-location__advantagesBlocks--image">
                  {image}
                </div>
                <div className="m-location__advantagesBlocks--text">
                  <h3>{title}</h3>
                  <p>{text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Location;
