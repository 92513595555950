import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

const query = graphql`
  {
    team1: file(relativePath: { eq: "images/about-us/team-1.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    team2: file(relativePath: { eq: "images/about-us/team-2.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    one: file(relativePath: { eq: "images/about-us/branko.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    two: file(relativePath: { eq: "images/about-us/roberto.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    three: file(relativePath: { eq: "images/about-us/jelena.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    four: file(relativePath: { eq: "images/about-us/florian.png" }) {
      name
      childImageSharp {
        fluid(quality: 99, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    five: file(relativePath: { eq: "images/about-us/yui.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    six: file(relativePath: { eq: "images/about-us/vukasin.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seven: file(relativePath: { eq: "images/about-us/katarina.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eight: file(relativePath: { eq: "images/about-us/dusan.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nine: file(relativePath: { eq: "images/about-us/djordje.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ten: file(relativePath: { eq: "images/about-us/boris.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Team = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query);

  const teamContent = [
    {
      image: data.one.childImageSharp.fluid,
      name: "Branko Mitrović",
      position: "CEO",
    },
    {
      image: data.two.childImageSharp.fluid,
      name: "Roberto Maione",
      position: "Head of Sales",
    },
    {
      image: data.three.childImageSharp.fluid,
      name: "Jelena Vukovic",
      position: "COO",
    },
    {
      image: data.four.childImageSharp.fluid,
      name: "Florian Paschka",
      position: "CBDO",
    },
    {
      image: data.five.childImageSharp.fluid,
      name: "Yui Chan",
      position: "Head of Research",
    },
    {
      image: data.six.childImageSharp.fluid,
      name: "Vukašin Daskalović",
      position: "Delivery Menager",
    },
    {
      image: data.seven.childImageSharp.fluid,
      name: "Katarina Belić",
      position: "XRM Departman Lead",
    },
    {
      image: data.eight.childImageSharp.fluid,
      name: "Dušan Petrović",
      position: "CTO",
    },
    {
      image: data.nine.childImageSharp.fluid,
      name: "Đorđe Knežević",
      position: "Head of eCommerce",
    },
    {
      image: data.ten.childImageSharp.fluid,
      name: "Boris Grebnjev",
      position: "Sys Admin Team Lead",
    },
  ];

  return (


    <section className="m-team m-tabPanel">
      <div className="_wr">
        <div className="_w">
          <div className="_12">
            <h2 className="m-team__title -thin">{t('aboutUs.team.people')}</h2>
            <span className="a-line -red"></span>
            <div className="m-team__section">

              <p>{t('aboutUs.team.firstText')}</p>
              <div className="_w m-team__images">
                <div className="_m6">
                  <div className="m-team__images--image">
                    <Image fluid={data.team1.childImageSharp.fluid} />
                  </div>
                </div>
                <div className="_m6">
                  <div className="m-team__images--image">
                    <Image fluid={data.team2.childImageSharp.fluid} />
                  </div>
                </div>
              </div>
              <div className="m-team__text">
                <p>{t('aboutUs.team.secondText')}</p>
                <p>{t('aboutUs.team.thirdText')}</p>
              </div>
            </div>
          </div>
          <div className="_12">
            <h2 className="m-team__title -thin -management">{t('aboutUs.team.management')}</h2>
            <span className="a-line -red"></span>
          </div>
          <div className="m-team__section">
            <div className="wrap">
              {teamContent.map((item, i) => {
                return (
                  <div key={i} className="_8 ofs_2 ofs_s0 _s6 _l3 m-team__itemContent">
                    <div className="m-team__itemContent--image">
                      <Image fluid={item.image} />
                    </div>
                    <h3 className="m-team__itemContent--name">{item.name}</h3>
                    <p className="m-team__itemContent--job">{item.position}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>


  );
};

export default Team;
