import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";


const Mission = () => {
  const { t } = useTranslation()
  return (
    <section className="m-missionVision section-padding">
      <div className="_wr">
        <div className="_w">
          <div className="_m6">
            <h2 className="-thin">{t('aboutUs.missionVision.mission')}</h2>
            <span className="a-line -red"></span>
            <p>{t('aboutUs.missionVision.missionText')}</p>
          </div>
          <div className="_m6 m-aboutUs.missionVision__vision">
            <h2 className="-thin">{t('aboutUs.missionVision.vision')}</h2>
            <span className="a-line -red"></span>
            <p>{t('aboutUs.missionVision.visionText')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
